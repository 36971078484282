<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols='12'>
          <v-card-title >
            9. Действия по предотвращению паники
          </v-card-title>
        </v-col>
        <v-col
          cols="12"
          class="pb-0"
        >
            <v-alert
              border="left"
              colored-border
              color="red"
              class="title font-weight-light pl-10"
              elevation="2"
              height="130"
            >
            <strong>Паника (безотчетный страх)  </strong> - психологическое состояние, вызванное воздействием внешних условий, которые угрожают жизни. Выражается в чувстве острого страха, охватывающего человека или множество людей, которые неконтролируемо стремятся избежать опасной ситуации.
          </v-alert>
        </v-col>
        <v-col cols="9">
          <v-card-title >
            Два типа панической реакции:
          </v-card-title>
          <v-card class="d-flex" flat>
            <v-card-text class="title font-weight-light">
              <p><span class="red--text font-weight-bold">Ступор (оцепенение)</span> – притупление сознания, потеря способности правильно воспринимать и оценивать обстановку, сильная расслабленность, вялость действий, общая заторможенность и в крайней степени неподвижность.</p>
              <p>Человек физически неспособен действовать и выполнять команды.</p>
            </v-card-text>
            <div absolute>
           <v-img
              lazy-src="img/material/panic-img.png"
              width="100%"
              height="300"
              src="img/material/panic-img.png"
              class="ma-1"
              contain
            ></v-img>
            </div>
          </v-card>

          <v-card class="d-flex" flat>
          <div absolute>
           <v-img
              lazy-src="img/material/fuga.png"
              width="300"
              src="img/material/fuga.png"
              class="ma-1"
              contain
            ></v-img>
          </div>
            <v-card-text class="title font-weight-light text-right">
              <p><span class="red--text font-weight-bold">Фуга (бег) </span> – хаотическое метание, дрожание рук, тела, голоса, ускоренная речь, непоследовательные высказывания. </p>
              <p>Наблюдается у 85-90% людей, оказавшихся в опасной ситуации.</p>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card>
            <v-card-title>
              Чем опасна паника?
            </v-card-title>
            <v-card-text class="title font-weight-light">
              Люди стремятся скорее покинуть горящее помещение, скучиваются у входов и закупоривают их. Многие из бегущих людей способны к объективной оценке ситуации и разумным действиям, но испытывают страх и заражают им других.
            </v-card-text>
          </v-card>

          <v-dialog
            v-model="dialog1"
            width="80%"
            class="pa-16"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                block
                height="50"
                color="red"
                class="mt-5"
                v-on="on"
                v-bind="attrs"
                >
                  Как себя вести?
              </v-btn>
            </template>
            <v-card class="noselect">
              <v-card-title class="headline">
                Правила поведения и противодействия распространению паники:
              </v-card-title>
              <v-card-text class="title font-weight-light mb-0 pb-0">
                <v-icon size="50" color="red">mdi-numeric-1</v-icon>
                Услышав крики <strong>«Пожар!»</strong>, постарайтесь сохранить спокойствие и выдержку. Призывайте к этом находящихся рядом людей;
              </v-card-text>
              <v-card-text class="title font-weight-light mb-0 pb-0">
                <v-icon size="50" color="red">mdi-numeric-2</v-icon>
                Оцените обстановку, убедитесь в наличии реальной опасности. Возможно, этим криком кто-то хочет привлечь внимание людей;
              </v-card-text>
              <v-card-text class="title font-weight-light mb-0 pb-0">
                <v-icon size="50" color="red">mdi-numeric-3</v-icon>
                Стоя на месте, внимательно оглядитесь вокруг. Увидев телефон или кнопку пожарной сигнализации, сообщите о пожаре в пожарную охрану. Не считайте, что это кто-то сделает за вас!;
              </v-card-text>
              <v-card-text class="title font-weight-light mb-0 pb-0">
                <v-icon size="50" color="red">mdi-numeric-4</v-icon>
                Спокойно двигайтесь к ближайшему аварийному выходу. Опасайтесь рвущейся к выходу толпы;
              </v-card-text>
              <v-card-text class="title font-weight-light mb-0 pb-0">
                <v-icon size="50" color="red">mdi-numeric-5</v-icon>
                Не пытайтесь спасти свои вещи!;
              </v-card-text>
              <v-card-text class="title font-weight-light mb-0 pb-0">
                <v-icon size="50" color="red">mdi-numeric-6</v-icon>
                Помните, что в специальных местах есть огнетушители;
              </v-card-text>
              <v-card-text class="title font-weight-light mb-0 pb-0">
                <v-icon size="50" color="red">mdi-numeric-7</v-icon>
                Двигайтесь в толпе, задерживайте обезумевших людей;
              </v-card-text>
              <v-card-text class="title font-weight-light mb-0 pb-0">
                <v-icon size="50" color="red">mdi-numeric-8</v-icon>
                Если можете, возьмите на себя руководство людьми. Помогите тем, кто скован страхом и не может двигаться. Для приведения их в чувство бейте ладонями по щекам, разговаривайте спокойно и внятно, поддерживайте их под руки;
              </v-card-text>
              <v-card-text class="title font-weight-light mb-0 pb-0">
                <v-icon size="50" color="red">mdi-numeric-9</v-icon>
                Выбравшись из толпы, окажите помощь пострадавшим из-за паники. Перенесите их на свежий воздух, расстегните одежду, вызовите «Скорую помощь».
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red"
                  text
                  @click="dialog1 = false"
                >
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
      <Hint></Hint>
    </v-container>
  </div>
</template>
<script>
import Hint from '@/components/Hint.vue'
export default {
  data: () => ({
    dialog1: null
  }),
  components: {
    Hint
  }
}
</script>
